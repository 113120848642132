import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const BannerSub = ({ getSubcategoriesByCategoryId }) => {
  const { items: category } = useSelector((state) => state.category);

  const [open, setOpen] = useState("");

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);
  };

  return (
    <Card className="category-thumb  overflow-y-scroll h-[20rem] overflow-x-hidden  w-full   p-4 shadow-xl shadow-blue-gray-900/5 rounded-none">
      <List>
        {category?.map(
          (category, index) =>
            +category.featured === 1 && (
              <Accordion
                key={index}
                open={open === index}
                icon={
                  getSubcategoriesByCategoryId(category.id)?.length > 0 && (
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`mx-auto h-4 w-4 transition-transform ${
                        open === index ? "rotate-180" : ""
                      }`}
                    />
                  )
                }
              >
                <ListItem className="p-0" selected={open === 1}>
                  <AccordionHeader
                    onClick={() => handleOpen(index)}
                    className="border-b-0 py-3"
                  >
                    <ListItemPrefix className="h-5 w-5 overflow-hidden rounded">
                      <img
                        src={`${process.env.REACT_APP_URL}/uploads/category/${category?.icon}`}
                        alt={category.alt}
                        className="w-full h-full object-cover"
                      />
                    </ListItemPrefix>
                    <Link
                      to={`/categorypage/${category?.id}`}
                      color="blue-gray"
                      className="mr-auto font-normal text-sm"
                    >
                      {category?.name}
                    </Link>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody className="py-1">
                  <List className="p-0">
                    {getSubcategoriesByCategoryId(category?.id).map(
                      (subCat) => (
                        <Link to={`subcategory/${subCat.id}`} key={subCat?.id}>
                          <ListItem>
                            <ListItemPrefix className="h-3 w-5">
                              <ChevronRightIcon strokeWidth={3} />
                              {/* <img
                      src={`${process.env.REACT_APP_URL}/uploads/subcategory/${subCat.banner}`}
                      alt=""
                    /> */}
                            </ListItemPrefix>
                            {subCat?.name}
                          </ListItem>
                        </Link>
                      )
                    )}
                  </List>
                </AccordionBody>
              </Accordion>
            )
        )}
      </List>
    </Card>
  );
};

export default BannerSub;
